.container {
  box-sizing: border-box;
  height: 200px;
  padding: 4px;
  overflow-y: auto;
  cursor: text;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: 0;
}

.container *,
.container *::before,
.container *::after {
  box-sizing: inherit;
}

.container.container--focused {
  border-color: #0096cc;
}

.container.container--errors {
  border-color: #db3d44;
}

.container .autosized-wrapper {
  float: left;
  margin-right: 4px;
  overflow: hidden;
}

.container .autosized-wrapper input {
  height: 32px;
  padding: 0;
  margin: 0;
  color: #222;
  cursor: text;
  background: none;
  border: 0;
  outline: 0;
  box-shadow: none;
}

.container .token-list .token {
  display: flex;
  flex-flow: row nowrap;
  place-content: center center;
  align-items: center;
  float: left;
  max-width: 100%;
  height: 32px;
  padding: 0;
  margin: 0 4px 4px 0;
  line-height: 1.5;
  color: #0096cc;
  white-space: nowrap;
  cursor: default;
  background-color: #cceaf5;
  border: 0;
  border-radius: 4px;
}

.container .token-list .token:hover {
  background-color: #e6f4fc;
}

.container .token-list .token.token--error {
  color: #db3d44;
  background-color: #f9b5b5;
}

.container .token-list .token.token--error:hover {
  background-color: #ffdada;
}

.container .token-list .token.token--read-only:hover {
  background-color: #cceaf5;
}

.container .token-list .token.token--read-only.token--error:hover {
  background-color: #f9b5b5;
}

.container .token-list .token.token--editable:hover {
  cursor: pointer;
}

.container .token-list .token.token--active .autosized-wrapper {
  display: flex;
  place-content: center center;
  align-items: center;
  height: 32px;
  margin: 4px 8px;
}

.container .token-list .token.token--active .autosized-wrapper input {
  height: auto;
  border-bottom: 1px solid #aaa;
}

.container .token-list .token .token__label-wrapper {
  flex: 1 0 0;
  margin: 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container .token-list .token .token__delete-button {
  display: flex;
  flex: 0 0 0;
  place-content: center center;
  align-items: center;
  height: 100%;
  padding: 2px 8px;
  cursor: pointer;
  border-left: 1px solid #aaa;
  border-radius: 0 4px 4px 0;
  opacity: 0.3;
}

.container .token-list .token .token__delete-button .delete-button__close-icon {
  position: relative;
  width: 14px;
  height: 14px;
}

.container .token-list .token .token__delete-button .delete-button__close-icon::before, .container .token-list .token .token__delete-button .delete-button__close-icon::after {
  position: absolute;
  left: 6px;
  height: 14px;
  content: '';
  border-left: 2px solid #222;
}

.container .token-list .token .token__delete-button .delete-button__close-icon::before {
  transform: rotate(-45deg);
}

.container .token-list .token .token__delete-button .delete-button__close-icon::after {
  transform: rotate(45deg);
}

.container .token-list .token .token__delete-button:hover {
  background-color: #aaa;
  opacity: 1;
}

.container .token-list .token .token__delete-button:hover .delete-button__close-icon::before, .container .token-list .token .token__delete-button:hover .delete-button__close-icon::after {
  border-color: #fff;
}
