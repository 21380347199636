
.exportButton {
    float: right
}

.gridGear{
    cursor: pointer;
}

.documentSearchInputTitle {
    font-size: 1.5em;
}

#searchtext {
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 100px);
    display: inline;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.DocumentSearchBtn {
    vertical-align: top !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}

.gridExport {
    margin-left: auto;
    background: #0d6efd !important;
    border-color: #0d6efd !important;
}

.gridLink {
    text-decoration: underline !important;
    cursor: pointer !important;
}

.documentModal {
    min-width: 60%;
    height: 90%;
}

.documentModal .modal-content {
    height: 100%;
}

.LangugeLabel {
    margin-right: 5px;
}

.homelink {
    font-size: 32px !important;
    color: #b6bab4;
}

.k-pager-info {
    display: block !important;
}

.k-pager-numbers .k-button {
    color: black;
}

.k-loading-mask {
    text-align: center;
    position: absolute !important;
}

.sphera-spinner {
    margin-top: 30px;
}

.dividerLine {
    margin-bottom: 10px;
    border: none;
    height: 1px;
    background-color: #333;
}

.LanguageSelectorDiv {
    float: right;
}

.LanguageSelectorDiv .sphera-dropdown {
    display: inline-block;
}

.sch-popup-container {
    height: calc(90vh - 60px) !important;
}

#logoPreview {
    max-height: 100px;
}

.logoDiv {
    height: 124px;
}

.logoUpload {
    margin-top: 34px;
}

.pageHeader {
    font-size: 24px;
}

.previewCell {
    padding-left: 100px;
}

.filter-button {
    height: 29.6px;
    padding-right: 4px !important;
    margin-right: 1px;
}

.k-dropdown-button.filter-button .k-button.k-button-solid.k-button-solid-base {
    padding-right: 4px !important;
}

.filter-clear-button {
    width: 29.6px;
    height: 29.6px;
}

.modalButton {
    display: inline-block;
    margin: 3px;
}

.modalButtonRow {
    float: right;
    display: inline-block;
}

.modalButtonRowLeft {
    display: inline-block;
}

.k-grid-content.k-virtual-content {
    overflow-y: scroll !important;
}

img.sch-cobranding-img {
    object-fit: contain;
    height: 96%;
    margin-left:0;
}

.columnButtonContainer {
    position: relative;
    display: inline-block;
    float: right;
    margin-right: 151px;
    margin-top: -111px;
}

.columnButtonContainerGrid {
    position: relative;
    display: inline-block;
}

.columnDropdown {
    position: absolute;
    z-index: 5;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
    background: white;
    border-radius: 5px;
    left: -120px;
    top: 100%;
    width: max-content;
}
.columnDropdown .k-column-list-item input[type='checkbox'] {
    margin-right: 4px;
}

.k-toolbar {
    overflow: visible !important;
}

.card .dsCardHeader {
    cursor: pointer;
    background-color: rgb(0 0 0 / 0%);
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    min-height: 64px;
    align-items: center;
    display: flex;
    border-bottom-color: #c6ccd6;
}

.dsCard {
    border-radius: 8px !important;
    box-shadow: 0 0 4px 0 rgba(0,0,0,.1);
}

.uploadLabelButton {
    width: 130px;
}

.uploadButtonDiv {
    display: inline-block;
    margin-right: 30px;
}

span.selectable, pre.selectable {
  -webkit-user-select:text;  /* Chrome 49+ */
  -moz-user-select: text;     /* Firefox 43+ */
  -ms-user-select: text;      /* No support yet */
  user-select:text;      
}
.w-fit-content {
    width: fit-content;
    block-size: fit-content;
}

span.selectable.material-id:hover {
    text-decoration:underline;
}

.copy-clipboard {
    margin-right: 4px;
    font-weight: bold;
    font-size: 1.2rem;
}
.copy-clipboard:hover {
    cursor: hand;
    color: #0877A7;
    font-size: 1.3rem;
}

.copy-clipboard-copied {
    color: #33BB44;
    margin-left: 4px;
}

th.k-header.active .k-grid-column-menu {
    background-color: #bbbcbf !important;
}

#phraseRbg .sphera-radio-button-group {
    margin-top: -10px;
}

.input-container > .k-input {
    width: 100% !important;
}

.buttonDiv .sphera-button {
    display: inline-block !important;
    margin-right: 10px;
}

.k-list-content {
    z-index: 101;
}

.k-animation-container.k-animation-container-relative.k-animation-container-shown {
    z-index: 10010 !important;
}

.sphera-notification .k-notification-group {
    z-index: 10011 !important;
}

.Ds-filterlogicpicker {
    width: 100px !important;
}

.Ds-filterdatepicker {
    margin-bottom: 10px !important;
}

.react-nav .side-nav-content{
    overflow: auto !important;
}

.k-grid-header-menu .k-icon svg {
    vertical-align: baseline;
}

/* .sphera-grid .k-grid-header-wrap .k-filter-row th, .sphera-grid.k-grid .k-grid-container .k-master-row td, .sphera-grid.k-grid .k-grid-header th.k-header {
    padding: 8px 0px 8px 12px !important;
} */

/* .sphera-grid .k-pager-sizes .k-dropdownlist.k-picker .k-icon-button.k-input-button {
    margin-top: 6px !important;
} */

/* div .sphera-grid .k-pager.k-grid-pager .k-pager-nav.k-disabled .k-icon {
    background-color: transparent !important;
} */

.k-column-resizer {
    cursor: col-resize !important;
}

.dsCriteriaDiv .dsIncludeToggle {
    margin-left: 10px;
}

.dsCriteriaDiv .dsEndDate {
    margin-left: 10px;
}

.dsStartDateLabel {
    margin-bottom: 0px !important;
    margin-top: 10px;
}

.dsEndDateLabel {
    margin-left: 177px;
    margin-bottom: 0px !important;
}

.dsCriteriaDiv{
    margin-bottom: 10px;
}

.dsCriteriaButtonDiv {
    margin-bottom: 10px;
}

.sphera-textarea span{
    max-height: 64px;
}

.configSaveBtn{
    float: right;
}

.modal-footer div:first-child {
    margin-right: auto !important;
}

.gridLinkDiv {
    margin-top: -45px;
    float: right;
}

.k-external-dropzone-hover {
    border: 3px dashed green;
}
.k-dropzone.k-upload-dropzone {
    display: none !important;
}

.container-image {
    position: relative;
    width: 90%;
    max-width: auto;
    margin: auto;
    overflow: hidden;
    height:240px;
  }
  
  .container-image .content-overlay {
    background: rgba(220, 220, 220, 0.85);
    position: absolute;
    height: 99%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }
  
  .container-image:hover .content-overlay {
    opacity: 1;
  }
  
  .content-image {
    width: 100%;
    object-fit:scale-down;
     
    min-height: 225px; 
    max-height: 225px; 
  }
  
  .content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 20%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  
  .container-image:hover .content-details {
    height: 100%;
    left: 50%;
    opacity: 1;
    top: 51%;
  }
  
  .content-details h3 {
    color: #fff;
    padding-top: 15px;
     padding-bottom: 15px;
    text-transform: uppercase;
    font-size: 34px;
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 800;
    line-height: 34px;
  }
  
  .content-details p {
    color: #fff;
    font-size: 15px;
    text-align: left;
    margin-top: 0;
  }
  
  .fadeIn-bottom {
    top: 80%;
  }
  
  .container-image a {
    bottom: 18px;
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    left: 15px;
    width: 100%;
    text-align: left;
    color: #fff;
    text-decoration: none;
  }

.MaterialSynoymsModal {
    margin-top: 20px;
}

div.k-animation-container.k-animation-container-shown {
    z-index: 10010 !important;
}
.dsCriteriaDiv .sphera-dropdown {
    display: inline-flex;
    margin-left: 10px;
}

.sphera-header .sphera-container-left { 
    margin-right: 0px !important;
}

.sphera-header .sphera-container-left div p {
    width: 50vw !important;
}

.searchNotValid {
    border-color: red !important;
}

    .searchNotValid:focus {
        box-shadow: 0 0 0 0.2rem rgb(255 0 0 / 25%) !important;
    }

.searchNotValidText {
    color: red;
    font-size: 10px;
    margin-bottom: 19px;
}

.searchNotValidTextHidden {
    visibility: hidden;
    margin-bottom: 10px;
}

.hiddenIcon span a {
    display: none !important;
}

.checkAllDocs {
    margin-right: auto !important;
    margin-left: 10px !important;
}

.ChangeDocumentSensitivityModalDiv {
    margin: 10px;
}

.btn-close {
    border: 0;
    border-radius: 0.25rem;
    opacity: .5;
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;
}

    .btn-close:focus {
        border: 0 !important;
        opacity: .5;
        cursor: pointer;
    }

div.sphera-radio-button-group .k-radio-list .k-radio-label, div.sphera-radio-button-group > .k-radio-list > li.k-radio-item > .k-radio-label {
    font-family: Inter, sans-serif !important;
}

.sphera-checkbox .k-checkbox-label, .sphera-checkbox .k-checkbox.k-invalid + .k-checkbox-label {
    font-family: Inter, sans-serif !important;
}

.no-permissions {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

    .no-permissions .graphic-container {
        margin-top: 0px !important;
    }
        .no-permissions .graphic-container .sphera-background {
            margin-top: 0px !important;
        }

.k-column-list-wrapper.columnDropdown {
    z-index: 101 !important;
}

.gridAddMargin{
    margin-bottom: 45px;
}

.MaterialSynoymsModal .k-window-content .content-text {
    max-height: 100% !important;
}

.MaterialSynoymsModal .k-window-content {
    padding: 0 0 0px !important;
}

.k-animation-container.k-animation-container-relative {
    width: 100%;
}

.documentModal .k-window-content .content-text {
    max-height: 100% !important; 
    overflow: hidden !important;
    height: 100%;
}

.sphera-modal-wrapper.documentModal .k-window-content {
    padding: 0 0 5px;
}

.k-loading-mask.general {
    position: absolute !important;
}

.ClearDocumentsModalDiv{
    padding: 10px;
}

.SyncSCMUsersModalDiv {
    padding: 10px;
}

.clearDocumentsText {
    display: inline-block;
    width: 331px;
    vertical-align: super;
    padding-left: 10px;
}

.customerColumnTitlePadding{
    padding-top: 8px;
}

.customerColumnLogoPadding {
    padding-top: 12px;
}

.localizationLink {
    cursor: pointer;
}

.ColumnsNameHeader {
    padding-right: 145px;
    padding-left: 150px;
    font-size: 12px;
}

.ColumnsNameHeaderPs {
    padding-right: 145px;
    font-size: 12px;
}

.ColumnsPrivateHeader {
    padding-right: 100px;
    font-size: 12px;
}

.ColumnsPublicHeader {
    padding-right: 150px;
    font-size: 12px;
}

.materialDetailsTab .sphera-tab .k-tabstrip .k-tabstrip-content {
    padding: 15px 15px 0px 15px;
}

.hidden {
    display: none !important;
}

.k-child-animation-container div .DocDetailsRow {
    margin-left: 0px;
    margin-right: 0px;
    width: 99.5%;
}

.documentTabs {
    height: 100%;
}

.documentTab {
    height: 100%;
}

.documentModal div div .sphera-tab {
    height: 100% !important;
}



.documentTabs .k-tabstrip-content {
    padding-bottom: 0px;
}


.rowHover:hover {
    background: #d9d9d9ee;
}

.sortable div div{
    border: 0px !important;
}

.sortable div div[aria-grabbed="true"] .rowHover {
    background: #d9d9d9ee;
}

.valueField {
    word-break: break-word;
}

.Hazardslogo{
    width: 285px;
}

.nfpaFlame {
    position: absolute;
    margin-top: -176px;
    margin-left: 132px;
    font-size: 22px;
    color: black;
}

.nfpaHealth {
    position: absolute;
    margin-top: -127px;
    margin-left: 81px;
    font-size: 22px;
    color: black;
}

.nfpaInstability {
    position: absolute;
    margin-top: -126px;
    margin-left: 183px;
    font-size: 22px;
    color: black;
}

.nfpaSpecial {
    position: absolute;
    margin-top: -75px;
    margin-left: 119px;
    font-size: 22px;
    color: black;
    width: 42px;
    text-align: center;
}

.hmisFlame {
    position: absolute;
    margin-top: -155px;
    margin-left: 236px;
    font-size: 22px;
    color: black;
}

.hmisHealth {
    position: absolute;
    margin-top: -207px;
    margin-left: 238px;
    font-size: 22px;
    color: black;
}

.hmisCronic {
    position: absolute;
    margin-top: -207px;
    margin-left: 195px;
    font-size: 22px;
    color: black;
}

.hmisPH {
    position: absolute;
    margin-top: -100px;
    margin-left: 238px;
    font-size: 22px;
    color: black;
}

.hmisPPE {
    position: absolute;
    margin-top: -32px;
    margin-left: 10px;
    font-size: 18px;
    color: black;
    text-align: right;
    width: 250px;
}

.propDiv {
    white-space: pre-line;
}

.SetWidthsBtn {
    margin: 0px !important;
    margin-top: -12px !important; 
}

#colWidth {
    text-align: center;
}

.widthButtonHeader {
    text-align: center;
}

    .widthButtonHeader .sphera-button {
        display: inline-block !important;
    }

.CustomColumnWidthModal{
    left: 10% !important;
}

.DeleteDocumentsModalDiv {
    margin: 15px;
}

.phraseSectionHeader {
    font-size: 15px;
    display: inline-block;
    width: 50%;
}

.phraseValueHeader {
    color: #0000ff;
    font-size: 14px;
    display: inline-block;
    width: 50%;
}

.phraseSubHeader {
    color: #0000ff;
    font-size: 14px;
    text-decoration: underline;
}

.gridRowTags .sphera-chip {
    display: inline-block;
    margin-right: 5px;
}

.k-menu:not(.k-context-menu) > .k-item {
    color: black !important;
}
.k-menu:not(.k-context-menu) > .k-item:hover {
    color: black !important;
}

.AddEditFilterModalBody {
    padding: 5px;
}

.modalFieldDiv {
    margin-bottom: 10px;
}

.dsCriteriaDiv .sphera-checkbox {
    display: inline-block;
    margin-left: 5px;
}