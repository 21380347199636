.footerFixed {
    position: fixed;
    left: 0;
    bottom: 0 !important;
    width: 100%;
    color: #f5f5f5;
    background-color: #242c67;
    -webkit-box-shadow: 0 0 8px 0 #000;
    -moz-box-shadow: 0 0 8px 0 #000;
    box-shadow: 0 0 8px 0 #000;
}

.footer {
    height: 36px;
    line-height: 36px;
    z-index: 100;
    clear: both;
}

.version {
    padding-left: 25px;
}

.copyright {
    padding-left: 40px;
}

a.footerLnk, a.footerLnk:link, a.footerLnk:visited {
    color: #f5f5f5;
    text-decoration: underline;
}

.privacyStatement {
    padding-left: 10px;
}

.footerLogo {
    position: absolute;
    right: 5px;
    top: 0;
    width: 148px;
    height: 36px;
    z-index: 10;
    cursor: pointer;
}

.footerLogoImg {
    width: 100%;
    height: 100%;
}

.versionSection {
    margin-left: 10px;
}