
.container-grid {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  
  .card:after {
    content: "";
    display: block;
  }
  
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .box div {
    width: 100px;
    height: 100px;
  }
        